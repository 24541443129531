import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useCreateBasketMutation } from 'api/basket';
import { productbasketCreate } from 'api/productBasket';
import { useTranslationQuery } from 'api/translations';
import { BasketSelector } from 'components/fragments';
import AccountSelectorBody from 'components/fragments/AccountSelectorBody';
import { DrawerBody, DrawerFooter, Button, DrawerLevel, DrawerHeaderWBackButton, AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import {
	BasketGroupingTypeRequest,
	CreateBasketRequest,
	ProductBasketRequest,
	ShipToInfoResponse,
} from 'generated/data-contracts';
import { messageToApp } from 'helpers/app';
import { formatTranslation } from 'helpers/stringHelpers';
import { useViewportSize } from 'helpers/useViewportSize';
import { useViewportHeight } from 'helpers/useViewportSizeHeight';
import styles from './AccountsView.module.scss';

interface AccountsViewProps {
	handleClose: () => void;
	handleBasketSelectorBack: () => void;
	productId?: string;
}

export const AccountsView: React.FC<AccountsViewProps> = ({ handleClose, handleBasketSelectorBack }) => {
	const { data: translations } = useTranslationQuery();

	const { isSmallScreenSize } = useViewportHeight();
	const { isMobile } = useViewportSize();
	const [isLoading, setIsLoading] = React.useState(false);
	const { notificationActions } = useNotificationContext();

	const location = useLocation();
	const familyId = (location.state as Record<string, string>)?.productFamilyId || '';

	const createBasket = useCreateBasketMutation();

	const [showBasketSelector, setShowBasketSelector] = useState(false);

	const [selectedShipTos, setSelectedShipTos] = useState<ShipToInfoResponse[]>([]);
	const shipToIds = selectedShipTos.map(({ id }) => id);

	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

	const [filterList, setFilterList] = React.useState<ShipToInfoResponse[]>([]);

	const onToggleClick = (): void => {
		setIsExpanded(!isExpanded);
	};

	const handleChange = (event): void => {
		const { value } = event.target;

		if (selectedShipTos.some((shipTo) => shipTo.id === value)) {
			// remove shipTo from selected list
			setSelectedShipTos(selectedShipTos.filter((shipTo) => shipTo.id !== value));

			// handle if selectedShipTos gets empty
			if (selectedShipTos.length === 1) {
				setIsExpanded(false);
			}
		} else {
			// find checked shipTo object from showed list
			const checkedShipTo = filterList.find((shipTo) => shipTo.id === value);
			// add shipTo to selected list
			if (checkedShipTo) setSelectedShipTos((prevState) => [...prevState, checkedShipTo]);
		}
	};

	const handleSubmit = (event): void => {
		event.preventDefault();

		const body: CreateBasketRequest = {
			selectBasket: true,
			shipToIds,
		};

		setIsLoading(true);

		createBasket.mutate(
			{ query: { body: body, familyId: familyId } },
			{
				onSuccess: () => {
					messageToApp({ type: 'basketChanged' });

					if (familyId) {
						const basketQuery: ProductBasketRequest = {
							basketDeliveryDate: '',
							groupingTypeRequest: BasketGroupingTypeRequest.Non,
							includeShipToGrouping: isMobile,
							basketLines: [],
							familyId,
						};

						productbasketCreate(basketQuery).catch(() => {
							notificationActions.addNotification({
								children: formatTranslation(translations?.shared.genericErrorMsg, {}),
								type: AlertTypes.DANGER,
							});
						});
					}

					setShowBasketSelector((prev) => !prev);
				},
				onSettled: () => {
					setIsLoading(false);
				},
			},
		);
	};

	return (
		<>
			<DrawerBody className={classNames(styles.body, { [styles.hasNoTopPadding]: isExpanded })}>
				<AccountSelectorBody
					handleChange={handleChange}
					onToggleClick={onToggleClick}
					isSelectedShipTosExpanded={isExpanded}
					setFilterList={setFilterList}
					selectedShipTos={selectedShipTos}
					setSelectedShipTos={setSelectedShipTos}
					isCreateBasketView
					handleSubmit={handleSubmit}
					formId="accountsViewForm"
				/>
			</DrawerBody>

			{showBasketSelector && (
				<DrawerLevel isActive={showBasketSelector}>
					<DrawerHeaderWBackButton
						title={translations?.openBaskets.title}
						handleBack={handleBasketSelectorBack}
						handleClose={handleClose}
					/>
					<BasketSelector shipTos={shipToIds} hasNoCreateView onClose={handleClose} />
				</DrawerLevel>
			)}

			<DrawerFooter className={styles.footer}>
				<Button
					form={'accountsViewForm'}
					type="submit"
					variant="dark"
					isLoading={isLoading}
					isFullWidth
					disabled={selectedShipTos.length === 0}
				>
					{translations?.openBaskets.edit.submit}
				</Button>

				<Button
					className={classNames({ ['u-mt-sm']: !isSmallScreenSize })}
					variant="secondary"
					isFullWidth
					onClick={handleClose}
				>
					{translations?.shared.cancel}
				</Button>
			</DrawerFooter>
		</>
	);
};
