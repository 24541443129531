import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
	InfiniteData,
	UseInfiniteQueryResult,
	UseSuspenseQueryResult,
	keepPreviousData,
	useInfiniteQuery,
	useSuspenseQuery,
} from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { convertStyleOptionIdToMasterId } from 'components/features/ProductDetail/productDetailUtils';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Product as ProductApi } from 'generated/Product';
import {
	AssortmentType,
	PricingDetailsResponse,
	ProductCategoryId,
	ProductFilter,
	ProductListResponse,
	ProductMasterId,
	ProductResponse,
	ProductSort,
	SortDirection,
} from 'generated/data-contracts';
import { isScannerApp } from 'helpers/app';
import { getInfiniteQueryNextPage } from 'helpers/getInfiniteQueryNextPage';
import { formatTranslation } from 'helpers/stringHelpers';
import { InitialState } from 'store/types';
import { RelewiseProductRecommendationQuery, getRelewiseProductRecommendationsQuery } from './recommendations';
import { useTranslationQuery } from './translations';

export interface ProductBundleQuery {
	skip?: number;
	take?: number;
	sortBy?: ProductSort;
	sortDirection?: SortDirection;
	phrase?: string;
	minPrice?: number;
	maxPrice?: number;

	productCategoryId?: string;
	preDefinedProductCategoryIds?: string[];
	customerGroupIds?: string[];
	productLineIds?: string[];
	collectionIds?: string[];
	productFilters?: ProductFilter[];
	excludedFilters?: ProductFilter[];
	preDefinedProductFilters?: ProductFilter[];
	productCatalogues?: string[];
	productSubcatalogues?: string[];
}

export const defaultProductBundlePageSize = 36;

export const useProductDetailQuery = (
	id: string,
	host?: string,
	cookies?: string,
): UseSuspenseQueryResult<ProductResponse> => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useSuspenseQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.product.fetch(id).queryKey,
		queryFn: async (): Promise<ProductResponse> => {
			const productListApi = new ProductApi({
				baseUrl: host,
				baseApiParams: { headers: setHeaders(cookies) || undefined },
			});
			const response = await productListApi.productDetail(id);

			if (!response.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.shared.layoutErrorMsg, {}),
					type: AlertTypes.DANGER,
				});
			}

			return response.data;
		},
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
	});
};

export const useProductDetailPricing = ({
	id,
	deliveryDate,
	assortmentType,
	masterId,
}: {
	id: string;
	deliveryDate?: string;
	assortmentType?: AssortmentType;
	masterId?: ProductMasterId;
}): UseSuspenseQueryResult<PricingDetailsResponse> => {
	return useSuspenseQuery({
		queryKey: queryKeys.product.pricing(id, deliveryDate, assortmentType, masterId).queryKey,
		queryFn: async (): Promise<PricingDetailsResponse> => {
			const productListApi = new ProductApi({
				baseApiParams: { headers: setHeaders() },
			});
			const response = await productListApi.productGetrequestedpriceCreate({
				assortmentType: assortmentType ?? AssortmentType.Boxes,
				deliveryDate: deliveryDate || '',
				familyId: id,
				masterIds: masterId ? [convertStyleOptionIdToMasterId(masterId)] : [],
			});

			return response.data;
		},
		refetchOnMount: true,
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
	});
};

export const getProductBundleQuery = async (
	query: ProductBundleQuery,
	page: number,
	resetPage?: boolean,
): Promise<ProductListResponse> => {
	const productListApi = new ProductApi({
		baseApiParams: { headers: setHeaders() },
	});

	const skipAmount = resetPage ? 0 : (page - 1) * (query.take || defaultProductBundlePageSize);

	const response = await productListApi.productSearchqueryCreate({
		skip: skipAmount,
		take: query.take || defaultProductBundlePageSize,
		...query,
		sortBy: query.sortBy || ProductSort.Relevance,
		sortDirection: query.sortDirection || SortDirection.Descending,
	});
	return response.data;
};

export const parseBundleQueryParameters = (
	searchParams: URLSearchParams,
	preDefinedFilters?: ProductFilter[],
	preDefinedProductCategoryIds?: ProductCategoryId[],
): ProductBundleQuery => {
	const filters = [] as ProductFilter[];
	const predefinedKeys = [
		'productLineIds',
		'collectionIds',
		'productCategoryId',
		'phrase',
		'page',
		'take',
		'pageSize',
		'sortBy',
		'sortDirection',
		'minPrice',
		'maxPrice',
		'productCatalogues',
		'productSubcatalogues',
	];
	searchParams.forEach((value, key) => {
		if (predefinedKeys.some((k) => k === key)) return;
		filters.push({
			attributeKey: key,
			attributeValues: value.split(','),
		});
	});

	const returnedBundleQueryParams = {
		productLineIds: searchParams.get('productLineIds')?.split(','),
		collectionIds: searchParams.get('collectionIds')?.split(','),
		productCategoryId: (searchParams.get('productCategoryId') as ProductCategoryId) || undefined,
		phrase: searchParams.get('phrase') || undefined,
		sortBy: (searchParams.get('sortBy') as ProductSort) || undefined,
		sortDirection: (searchParams.get('sortDirection') as SortDirection) || undefined,
		productFilters: filters,
		preDefinedProductFilters: preDefinedFilters,
		preDefinedProductCategoryIds: preDefinedProductCategoryIds,
		productCatalogues: searchParams.get('productCatalogues')?.split(','),
		productSubcatalogues: searchParams.get('productSubcatalogues')?.split(','),
		minPrice: Number(searchParams.get('minPrice')) || undefined,
		maxPrice: Number(searchParams.get('maxPrice')) || undefined,
	};

	Object.keys(returnedBundleQueryParams).forEach((key) => {
		if (returnedBundleQueryParams[key] === undefined) {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete returnedBundleQueryParams[key];
		}
	});

	return returnedBundleQueryParams;
};

const isProductBundleQuery = (
	query: ProductBundleQuery | RelewiseProductRecommendationQuery,
): query is ProductBundleQuery => (query as RelewiseProductRecommendationQuery).type === undefined;

export const useProductBundleQuery = (
	query: ProductBundleQuery | RelewiseProductRecommendationQuery,
): UseInfiniteQueryResult<InfiniteData<ProductListResponse>> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	// MergedQuery is a combination of the query from the CMS and the url params (if there's only 1 cms block)
	const queryKey = useMemo(
		() =>
			isProductBundleQuery(query)
				? queryKeys.product.bundleQuery(segmentationId, query).queryKey
				: queryKeys.recommendations.relewise(segmentationId, query as RelewiseProductRecommendationQuery)
						.queryKey,
		[segmentationId, query],
	);

	return useInfiniteQuery({
		// currentPage should not be part of the key because react-query controls it internally
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		queryFn: async ({ pageParam }) => {
			if (isProductBundleQuery(query)) {
				return getProductBundleQuery(query as ProductBundleQuery, pageParam);
			} else {
				return getRelewiseProductRecommendationsQuery(query as RelewiseProductRecommendationQuery);
			}
		},
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		getPreviousPageParam: (firstPage) => {
			const page = firstPage.pagingInformation.currentPage;
			return page > 1 ? page - 1 : undefined;
		},
		retry: false,
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
		placeholderData: keepPreviousData,
		initialPageParam: 1,
	});
};
